const $ = require('jquery')
$.DataTable = require('datatables.net-dt')

const dataVar = $('#directory_data').attr('data-program-src');
 

async function getData(url) {

    fetch(url)
        .then(response => response.json() )
        .then( data => {
            let department = [];
            let tab = '';
            //looping though JSON
            data.forEach(item => {
                 tab += `<tr>
                    <td>${item.name}</td>
                    <td>${item.title}</td>
                    <td>${item.department}</td>
                    <td>${item.phone}</td>
                    <td>${item.email}</td>
                 </tr>`

                 department.push(item.department.trim());

            });
            document.getElementById('directory-body').innerHTML = tab;
            //init DataTables
            let table = $('#myTable').DataTable({
                "data" : data,
                "columns" : [
                    { "data" : "name" },
                    { "data" : "title" },
                    { "data" : "department" },
                   // { "data" : "phone" },
                   { "data": "phone",
                    render: function ( data, type, row) {
                        return '<a href="tel:'+row.phone+'">'+data+'</a>';
                    }
                    },
                    { "data": "email",
                        render: function ( data, type, row) {
                            return '<a href="mailto:'+row.email+'">'+data+'</a>';
                        }
                    },
                ],
                "processing" : true,
                'responsive' : true,
                'language': {
                    'paginate' : {
                        "first": "First page",
                        "last": "Last page"
                    }
                }
            })

            department = department.filter( function(a){if (!this[a]) {this[a] = 1; return a;}}, {} );
            $(department).each(function(i, item) {
                $('.check23').prepend(  $( renderLi(item, 'locations') )  );
            });
            //DataTables Filter get column you want to filter with this is Departments or 2nd column
            $.fn.dataTable.ext.search.push(
                function( settings, data, dataIndex ) {
                    let   selectedItem = $('#list1').val();
                    const dataColumn = data[2];
                    
                    if( selectedItem == dataColumn || selectedItem === "*") {
                        return true
                    }

                    return false;
                }
            );

             
            $('#list1').on('change', function() {
                table.draw();
            })
           return table;
    })
}

getData(dataVar)

//The HTML for the individual categories lists
function renderLi(item, category)
{
    let title = item;
    let item_val = item.toLowerCase().split(' ').join('-').replace(/[^a-z0-9-]/gi, '');
    return '<option data-category="program" data-item="' + title + '" class="mixitup-control dropdown-cat-item checkbox-' + item_val + '" data-name="' + title + '" value="' + title + '" type="checkbox" id="' + item_val + '" name="' + item_val + '"> <small>' + title +
        '</small></option>';
}

//Get Search
function filterColumn () {
    $('#myTable').DataTable().search(
        $('#searchbyname').val()
    ).draw();
}
$('#searchbyname').on('keyup', function() {
    filterColumn();
})

