jQuery(document).ready(function() {

  const textElements = document.querySelectorAll('.program-listings-card .card.card-body');
  const maxCharacters = 350; // adjust this value to set the maximum number of characters
  
  textElements.forEach(textElement => {
    const originalText = textElement.textContent;
  
    if (originalText.length > maxCharacters) {
      const truncatedText = originalText.substr(0, maxCharacters) + '...';
      textElement.textContent = truncatedText;
  
      //const spanElement = document.createElement('span');
      //spanElement.textContent = 'Learn More';
      //spanElement.classList.add('learn-more');
  
      //const linkElement = document.createElement('a');
      //linkElement.href = '#';
      //linkElement.appendChild(spanElement);
  
      //linkElement.addEventListener('click', function(e) {
        //e.preventDefault();
        //textElement.textContent = originalText;
        //textElement.removeChild(linkElement);
      //});
  
      //textElement.appendChild(document.createTextNode(' '));
      //textElement.appendChild(linkElement);
    }
  });
  
});

  