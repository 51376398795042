// ====================================
// VisionPoint Marketing
// Accordions JS
// ====================================
(function($) {

    function shouldToggleExpand(thisObject)
    {
        let openAmount = $(thisObject).find('.js-tabs-panel.is-open').length;
        let totalAmount = $(thisObject).find('.js-tabs-panel').length; 

        if (totalAmount == openAmount) {
            $(thisObject).find(".expand-all-btn").addClass("close-all");
        } else {
            $(thisObject).find(".expand-all-btn").removeClass("close-all");
        }
    }

    let accordions = [];
    $('.wf-accordion').each(function(id, data) {
        accordions.push(new AccordionTabs(data, {
            breakpoint: 800,
            tabsAllowed: false,
            selectedTab: 0,
            startCollapsed: true
        }));
        $(this).find('.expand-all-btn').on('click keypress', function() {
            
            let openAmount = $(this).closest('.wf-accordion').find('.js-tabs-panel.is-open').length;
            let closedAmount = $(this).closest('.wf-accordion').find('.js-tabs-panel').length - openAmount;            

            if (closedAmount == 0) {
                //Close them
                $(this).closest('.wf-accordion').find('.js-tabs-panel').removeClass('is-open');
                $(this).closest('.wf-accordion').find('.content').removeClass('is-open');
                $(this).closest('.wf-accordion').find('.js-tabs-panel').addClass('is-hidden');
                $(this).closest('.wf-accordion').find('.content').addClass('is-hidden');
            } else {
                //open them
                $(this).closest('.wf-accordion').find('.js-tabs-panel').addClass('is-open');
                $(this).closest('.wf-accordion').find('.content').addClass('is-open');
                $(this).closest('.wf-accordion').find('.js-tabs-panel').removeClass('is-hidden');
                $(this).closest('.wf-accordion').find('.content').removeClass('is-hidden');
            }
            shouldToggleExpand($(this).closest('.wf-accordion'));
        });
        $('.accordion-trigger').on('click keypress', function() {
            shouldToggleExpand($(this).closest('.wf-accordion'));
        });
    });

})(jQuery);    